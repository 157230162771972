import React from 'react';
import { TableBody, Typography } from '@material-ui/core';
import { format } from 'date-fns';
import history from '~/services/history';
import { useDisabledAccounts } from '../../hooks/useDisabledAccounts';

import * as S from './styles';
import DetailReason from './detailReason';
import DetailProducts from './detailProducts';

export default function DisabledAccountsTableBody() {
  const { disabledAccounts } = useDisabledAccounts();

  return (
    <TableBody>
      {disabledAccounts.map(disabledAccount => {
        const isDifferent =
          new Set([
            disabledAccount.idAplicacao,
            disabledAccount.compra.idAplicacao,
            disabledAccount.user.pessoa.idAplicacao,
          ]).size > 1;
        return (
          <S.TableRow key={disabledAccount.user.idPessoa}>
            <td>
              <S.ApcapNumber
                onClick={() =>
                  history.push(`/person/${disabledAccount.user.idPessoa}`)
                }
              >
                {disabledAccount.user.idPessoa}
              </S.ApcapNumber>
            </td>
            <td>
              <S.ContainerApplication>
                <Typography>{disabledAccount.application.nome}</Typography>

                {isDifferent && (
                  <DetailProducts disabledAccount={disabledAccount} />
                )}
              </S.ContainerApplication>
            </td>
            <td>
              {format(new Date(disabledAccount.dataInsert), 'dd/MM/yyyy HH:mm')}
            </td>
            <td>
              <S.ContainerRow>
                <S.CustomChip
                  background={disabledAccount.disabledAccountReasons.cor}
                >
                  <span>{disabledAccount.disabledAccountReasons.tag}</span>
                  {disabledAccount.textoMotivo && (
                    <DetailReason disabledAccount={disabledAccount} />
                  )}
                </S.CustomChip>
              </S.ContainerRow>
            </td>
          </S.TableRow>
        );
      })}
    </TableBody>
  );
}
