import React from 'react';
import { Table, Button, CircularProgress } from '@material-ui/core';
import { CgChevronDown } from 'react-icons/cg';
import * as S from './styles';
import DisabledAccountsTableHead from './table-head';
import DisabledAccountsTableBody from './table-body';
import { useDisabledAccounts } from '../../hooks/useDisabledAccounts';

export default function DisabledAccountsTable() {
  const { totalPages, page, setPage, loadingDocs } = useDisabledAccounts();

  return (
    <>
      <Table>
        <DisabledAccountsTableHead />

        <DisabledAccountsTableBody />
      </Table>

      {loadingDocs && (
        <S.Center>
          <CircularProgress />
        </S.Center>
      )}

      <Button
        disabled={totalPages === page || loadingDocs}
        onClick={() => setPage(page + 1)}
        variant="outlined"
        color="primary"
      >
        Carregar mais
        <CgChevronDown size={20} />
      </Button>
    </>
  );
}
