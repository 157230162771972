import React from 'react';
import * as S from './styles';
import DisabledAccountsFilters from './components/filters';
import { DisabledAccountsProvider } from './hooks/useDisabledAccounts';
import DisabledAccountsTable from './components/table';

export default function DisabledAccounts() {
  return (
    <DisabledAccountsProvider>
      <S.Container>
        <S.Title>Encerramento de contas</S.Title>

        <S.Content>
          <DisabledAccountsFilters />

          <DisabledAccountsTable />
        </S.Content>
      </S.Container>
    </DisabledAccountsProvider>
  );
}
