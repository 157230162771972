import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  MdVisibility as Visibility,
  MdVisibilityOff as VisibilityOff,
} from 'react-icons/md';
import { IoLogoGooglePlaystore, IoLogoAppleAppstore } from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import { TextField } from 'unform-material-ui';
import { Form } from '@rocketseat/unform';
import { toast } from 'react-toastify';
import { EvironmentTag } from '~/components';

import * as Yup from 'yup';
import {
  Button,
  CssBaseline,
  CircularProgress,
  Paper,
  Box,
  Grid,
  Typography,
  makeStyles,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import { PinInput } from 'react-input-pin-code';
import { IoMdExit } from 'react-icons/io';
import isEmpty from 'lodash/isEmpty';
import Toast from '~/components/Toast';
import {
  signInRequest,
  signInFinal,
  signOut,
} from '~/store/modules/auth/actions';
import Copyright from '~/components/Copyright';

import Logo from '~/assets/logo.png';
import banner from '~/assets/login-bg.jpg';
import { store } from '~/store';
import api from '~/services/api';
import { Container, Title, SubTitle, Paragraph, HintPassword } from './styles';

const schema = Yup.object().shape({
  email: Yup.string()
    .email('Favor informar um email válido.')
    .required('Favor informar o email.'),
  password: Yup.string()
    .required('Favor informar senha.')
    .min(6, 'A senha deve ter no mínimo 6 caracteres'),
});

const resetPasswordSchema = Yup.object().shape({
  resetPassword: Yup.string().required('Favor informar senha.'),
  resetPasswordRepeat: Yup.string().oneOf(
    [Yup.ref('resetPassword'), null],
    'As senhas não são iguais'
  ),
});

const useStyles = makeStyles(theme => ({
  root: {
    height: '100vh',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    height: '100%',
    alignItems: 'center',
  },
  logosContainer: {
    display: 'flex',
    fontSize: '2rem',
    gap: '6rem',
  },
  image: {
    backgroundImage: `url(${banner})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'left top',
  },
  paper: {
    margin: theme.spacing(8, 4),
    padding: '0 50px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  link: {
    display: 'flex',
    flexDirection: 'column',
    gap: '.5rem',
    textDecoration: 'none',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  qrCodeWhiteSpace: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 230,
    height: 230,
  },
}));

export default function Login() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { user, token } = store.getState().auth;
  const loading = useSelector(stored => stored.auth.loading);
  const success = useSelector(stored => stored.auth.success);
  const resetPass = useSelector(stored => stored.auth.resetPassword);
  const [values, setValues] = useState({
    showPassword: false,
  });
  const [oldPassword, setOldPassword] = useState('');
  const [repeatValues, setRepeatValues] = useState({ showPassword: false });
  const [qrCodeBase64, setQrCodeBase64] = useState(null);
  const [pins, setPins] = useState(Array.from({ length: 6 }, () => ''));
  const [resetPassword, setResetPassword] = useState(resetPass);
  const [ifMinCharacters, setIfMinCharacters] = useState(false);
  const [ifNumber, setIfNumber] = useState(false);
  const [ifLowerCase, setIfLowerCase] = useState(false);
  const [ifUpperCase, setIfUpperCase] = useState(false);
  const [ifSpecialCharacter, setIfSpecialCharacter] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [passwordValue, setPasswordValue] = useState('');

  useEffect(() => {
    if (resetPass) {
      localStorage.setItem('oldPassword', oldPassword);
    }
    setResetPassword(resetPass);
  }, [resetPass]);

  const qrCodeAlreadyReaded = useMemo(() => {
    return !isEmpty(user) ? !isEmpty(user.dateQrcodeRead) : false;
  }, [user]);

  const welcomeText = useMemo(() => {
    return qrCodeAlreadyReaded
      ? 'Insira o código gerado no Google Authenticator.'
      : 'Leia o QRCode abaixo com o aplicativo Google Authenticator. Em seguida, insira o código gerado no mesmo.';
    // eslint-disable-next-line
  }, [qrCodeAlreadyReaded, user]);

  function handleSubmit({ email, password }) {
    dispatch(signInRequest(email, password));
    setOldPassword(password);
  }

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleClickShowRepeatPassword = () => {
    setRepeatValues({
      ...repeatValues,
      showPassword: !repeatValues.showPassword,
    });
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  const getQrCode = useCallback(async () => {
    if (!success || isEmpty(user) || isEmpty(token) || qrCodeAlreadyReaded) {
      setQrCodeBase64(null);
      return;
    }

    try {
      const { data } = await api.post(
        '/two-factor-authentication/get-qrcode',
        {
          username: user.name,
          email: user.email,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setQrCodeBase64(data.qrcode);
    } catch (error) {
      toast.error(
        <Toast message="Falha ao obter o QRCode de autenticação" error />
      );
      dispatch(signOut());
    }
  }, [success, user, token, qrCodeAlreadyReaded, dispatch]);

  const handleSendPin = useCallback(() => {
    if (!pins[5]) return;

    const pinNumbers = pins.reduce((acumulado, atual) => {
      return acumulado + atual;
    });

    dispatch(signInFinal(pinNumbers, user, token, passwordValue));
  }, [pins, user, token, dispatch]);

  const handleQrcodeScreenExit = useCallback(() => {
    setQrCodeBase64(null);
    setPins(['', '', '', '', '', '']);
    dispatch(signOut());
  }, [dispatch]);

  useEffect(() => {
    handleSendPin();
  }, [pins, handleSendPin]);

  useEffect(() => {
    getQrCode();
  }, [success, getQrCode]);

  const handleChangePassword = event => {
    const { target } = event;

    if (target.value.length >= 12) {
      setIfMinCharacters(true);
    } else {
      setIfMinCharacters(false);
    }

    if (/[A-Z]/.test(target.value)) {
      setIfUpperCase(true);
    } else {
      setIfUpperCase(false);
    }

    if (/[a-z]/.test(target.value)) {
      setIfLowerCase(true);
    } else {
      setIfLowerCase(false);
    }

    if (/\d/.test(target.value)) {
      setIfNumber(true);
    } else {
      setIfNumber(false);
    }

    if (/[\W_]/.test(target.value)) {
      setIfSpecialCharacter(true);
    } else {
      setIfSpecialCharacter(false);
    }
  };

  // eslint-disable-next-line consistent-return
  const handleSubmitResetPassword = async value => {
    if (
      !ifLowerCase ||
      !ifMinCharacters ||
      !ifNumber ||
      !ifSpecialCharacter ||
      !ifUpperCase
    ) {
      return toast.error(
        <Toast message="A senha não atende aos critérios" error />
      );
    }

    try {
      setIsLoading(true);
      await api.put('/auth/reset-password', {
        username: user.email,
        password: value.resetPassword,
        oldPassword: localStorage.getItem('oldPassword') || '',
      });

      setResetPassword(false);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid
        item
        xs={12}
        sm={8}
        md={5}
        component={Paper}
        elevation={6}
        square
        style={{ alignContent: 'center' }}
      >
        <div className={classes.paper}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              width: '100%',
            }}
          >
            {success || resetPassword ? (
              <Button
                variant="text"
                endIcon={<IoMdExit />}
                onClick={() => handleQrcodeScreenExit()}
              >
                Sair
              </Button>
            ) : (
              <></>
            )}
          </div>
          <img
            src={Logo}
            alt="Logo Idea"
            width="100"
            style={{ marginBottom: 5 }}
          />

          <Typography
            style={{
              color: '#032554',
              fontWeight: 600,
              marginBottom: 20,
              fontSize: '28px',
              fontFamily: 'Roboto Slab',
            }}
            component="h1"
            variant="h5"
          >
            Login Backoffice Idea
          </Typography>

          <EvironmentTag />

          {success ? (
            <div className={classes.container}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <h1
                  style={{
                    textAlign: 'center',
                    fontSize: '1rem',
                    marginTop: '15px',
                    fontWeight: 'normal',
                    width: qrCodeAlreadyReaded ? '100%' : '50%',
                  }}
                >
                  {welcomeText}
                </h1>
                <h1
                  style={{
                    textAlign: 'center',
                    fontSize: '0.8rem',
                    fontWeight: 'normal',
                    color: '#757575',
                  }}
                >
                  Ou seu aplicativo de autenticação de preferência.
                </h1>
              </div>
              {!qrCodeAlreadyReaded && (
                <>
                  {qrCodeBase64 ? (
                    <img src={qrCodeBase64} alt="QRCODE" width={230} />
                  ) : (
                    <div className={classes.qrCodeWhiteSpace}>
                      <CircularProgress />
                    </div>
                  )}
                </>
              )}
              <PinInput
                values={pins}
                onChange={(value, index, valuesPin) => setPins(valuesPin)}
                validBorderColor="rgb(204,204,204)"
              />
              {loading && <CircularProgress />}
              {!qrCodeAlreadyReaded && (
                <>
                  <h1
                    style={{
                      textAlign: 'center',
                      fontSize: '0.9rem',
                      fontWeight: 'normal',
                      color: '#757575',
                    }}
                  >
                    Baixe o aplicativo nas lojas.
                  </h1>
                  <div className={classes.logosContainer}>
                    <a
                      className={classes.link}
                      href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=pt_BR&gl=US"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <IoLogoGooglePlaystore />
                      <span style={{ fontSize: '.75rem' }}>Android</span>
                    </a>
                    <a
                      className={classes.link}
                      href="https://apps.apple.com/us/app/google-authenticator/id388497605"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <IoLogoAppleAppstore />
                      <span style={{ fontSize: '.75rem' }}>IOS</span>
                    </a>
                  </div>
                </>
              )}
            </div>
          ) : (
            <>
              {!resetPassword && (
                <Form
                  schema={schema}
                  className={classes.form}
                  noValidate
                  onSubmit={props => handleSubmit(props)}
                >
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    autoFocus
                  />
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type={values.showPassword ? 'text' : 'password'}
                    id="password"
                    autoComplete="current-password"
                    onChange={e => setPasswordValue(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {values.showPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    style={{ backgroundColor: '#032554' }}
                    className={classes.submit}
                  >
                    {loading ? 'Carregando' : 'Entrar'}
                  </Button>
                  <Box mt={5}>
                    <Copyright />
                  </Box>
                </Form>
              )}
            </>
          )}
          {resetPassword && (
            <Container>
              <Title>Atualização obrigatória de senha</Title>
              <SubTitle>
                Por favor, atualize sua senha para preservar a segurança da sua
                conta.
                <br /> Digite sua nova senha no campo abaixo e clique em
                Atualizar Senha.
              </SubTitle>
              <Paragraph>
                Lembre-se de escolher uma senha forte utilizando no mínimo 12
                caracteres contendo ao menos uma letra maiúscula, uma letra
                minúscula, um número e um caractere especial.
              </Paragraph>
              <Form
                schema={resetPasswordSchema}
                noValidate
                onSubmit={props => handleSubmitResetPassword(props)}
                style={{
                  width: '60%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: 16,
                }}
              >
                <div style={{ width: '100%' }}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="resetPassword"
                    label="Nova senha"
                    type={values.showPassword ? 'text' : 'password'}
                    id="resetPassword"
                    autoComplete="current-password"
                    onChange={e => handleChangePassword(e)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {values.showPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      padding: '0 16px',
                    }}
                  >
                    <HintPassword valid={ifMinCharacters}>
                      12 CARACTERES
                    </HintPassword>
                    <HintPassword valid={ifUpperCase}>
                      LETRA MAIÚSCULA
                    </HintPassword>
                    <HintPassword valid={ifLowerCase}>
                      LETRA MINÚSCULA
                    </HintPassword>
                    <HintPassword valid={ifNumber}>NÚMERO</HintPassword>
                    <HintPassword valid={ifSpecialCharacter}>
                      CARACTERE ESPECIAL (@ ! # %)
                    </HintPassword>
                  </div>
                </div>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="resetPasswordRepeat"
                  label="Confirmar nova senha"
                  type={repeatValues.showPassword ? 'text' : 'password'}
                  id="resetPasswordRepeat"
                  autoComplete="current-password"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowRepeatPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {repeatValues.showPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <Button
                  disabled={loading}
                  type="submit"
                  style={{ width: '60%' }}
                  variant="contained"
                  color="primary"
                >
                  {isLoading ? 'Carregando...' : 'Atualizar senha'}
                </Button>
              </Form>
            </Container>
          )}
        </div>
      </Grid>
    </Grid>
  );
}
