import React, { useState } from 'react';
import { Popover, Typography, Button } from '@material-ui/core';
import { toast } from 'react-toastify';
import Toast from '~/components/Toast';
import { RiInformationLine } from 'react-icons/ri';
import { MdContentCopy } from 'react-icons/md';
import PropTypes from 'prop-types';
import * as S from './styles';

export default function DetailReason({ disabledAccount }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const openReason = Boolean(anchorEl);

  return (
    <>
      <S.InfoButton onClick={e => setAnchorEl(e.currentTarget)}>
        <RiInformationLine />
      </S.InfoButton>
      <Popover
        open={openReason}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          style: {
            borderRadius: '12px',
          },
        }}
      >
        <S.ContainerPopover>
          <Typography>{disabledAccount.textoMotivo}</Typography>

          <Button
            onClick={() => {
              navigator.clipboard.writeText(disabledAccount.textoMotivo);

              return toast.success(<Toast message="Texto copiado!" />);
            }}
          >
            <MdContentCopy color="#3F51B5" size={20} />
            <S.LabelCopy>Copiar texto</S.LabelCopy>
          </Button>
        </S.ContainerPopover>
      </Popover>
    </>
  );
}

DetailReason.propTypes = {
  disabledAccount: PropTypes.oneOfType([PropTypes.object]).isRequired,
};
