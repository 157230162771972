/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import {
  TextField,
  ListItemText,
  Checkbox,
  Divider,
  CircularProgress,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { format, subDays } from 'date-fns';
import * as S from './styles';
import DisabledAccountsPeriodDialog from './dialog';
import { useDisabledAccounts } from '../../hooks/useDisabledAccounts';

export default function DisabledAccountsFilters() {
  const {
    applications,
    reasons,
    productsSelecteds,
    setProductsSelecteds,
    reasonsSelecteds,
    setReasonsSelecteds,
    setPersonId,
    setStartDate,
    loading,
  } = useDisabledAccounts();

  const [openDialog, setOpenDialog] = useState(false);
  const [productsOptions, setProductsOptions] = useState([
    { value: 'all', label: 'Todos' },
    { value: 'wl', label: 'Apenas WLs' },
  ]);
  const [reasonsOptions, setReasonsOptions] = useState([
    { value: 'all', label: 'Todos' },
  ]);

  const handleChangeProducts = (_, option, reason) => {
    if (
      reason === 'remove-option' &&
      option.some(product => product.value === 'all')
    ) {
      setProductsSelecteds(option.filter(product => product.value !== 'all'));
    } else if (
      reason === 'remove-option' &&
      productsSelecteds.some(product => product.value === 'all') &&
      option.some(product => product.value !== 'all')
    ) {
      setProductsSelecteds([]);
    } else if (
      option.every(item =>
        applications
          .map(application => {
            return { value: application.code, label: application.title };
          })
          .some(
            application =>
              application.value === item.value &&
              application.label === item.label
          )
      ) &&
      applications
        .map(application => {
          return { value: application.code, label: application.title };
        })
        .every(application =>
          option.some(
            item =>
              item.value === application.value &&
              item.label === application.label
          )
        )
    ) {
      setProductsSelecteds([...option, { value: 'all', label: 'Todos' }]);
    } else if (option.some(product => product.value === 'all')) {
      setProductsSelecteds([
        { value: 'all', label: 'Todos' },
        ...applications.map(application => {
          return { value: application.code, label: application.title };
        }),
      ]);
    } else if (option.some(product => product.value === 'wl')) {
      setProductsSelecteds([
        { value: 'wl', label: 'Apenas WLs' },
        ...applications.map(application => {
          if (application.code === 'apcapsp') {
            return {};
          }
          return { value: application.code, label: application.title };
        }),
      ]);
    } else {
      setProductsSelecteds(option);
    }
  };

  const handleChangeReasons = (_, option, changeBy) => {
    if (
      changeBy === 'remove-option' &&
      reasonsSelecteds.some(reason => reason.value === 'all') &&
      option.some(reason => reason.value !== 'all')
    ) {
      setReasonsSelecteds([]);
    } else if (
      option.every(item =>
        reasonsOptions.some(
          reason => reason.value === item.value && reason.label === item.label
        )
      ) &&
      reasonsOptions.every(reason =>
        option.some(
          item => item.value === reason.value && item.label === reason.label
        )
      )
    ) {
      setReasonsSelecteds([...option, { value: 'all', label: 'Todos' }]);
    } else if (option.some(reason => reason.value === 'all')) {
      setReasonsSelecteds([
        { value: 'all', label: 'Todos' },
        ...reasonsOptions,
      ]);
    } else {
      setReasonsSelecteds(option);
    }
  };

  useEffect(() => {
    if (applications) {
      setProductsOptions(prevState => [
        ...prevState,
        ...applications.map(application => {
          return { value: application.code, label: application.title };
        }),
      ]);
    }
  }, [applications]);

  useEffect(() => {
    if (reasons) {
      setReasonsOptions(prevState => [
        ...prevState,
        ...reasons.map(reason => {
          return { value: reason.id, label: reason.tag, color: reason.cor };
        }),
      ]);
    }
  }, [reasons]);

  const handleFilterDate = option => {
    if (option.value === 7) {
      setStartDate(format(subDays(new Date(), 7), 'yyyy-MM-dd'));
    } else if (option.value === 15) {
      setStartDate(format(subDays(new Date(), 15), 'yyyy-MM-dd'));
    } else if (option.value === 'all') {
      setStartDate('');
    }
  };

  return (
    <S.Container>
      <TextField
        name="id"
        label="Nº APCAP"
        margin="normal"
        variant="outlined"
        style={{ maxWidth: '132px' }}
        onChange={e => {
          setPersonId(e.target.value);
        }}
      />

      <Autocomplete
        style={{ minWidth: '250px' }}
        id="period"
        options={[
          { value: 'all', label: 'Total' },
          { value: 7, label: 'Últimos 7 dias' },
          { value: 15, label: 'Últimos 15 dias' },
          { value: 'custom', label: 'Período personalizado' },
        ]}
        onChange={(_, option) => {
          if (option && option.value === 'custom') {
            setOpenDialog(true);
          }
          handleFilterDate(option);
        }}
        defaultValue={{ value: 'all', label: 'Total' }}
        getOptionLabel={option => option.label}
        renderInput={params => (
          <TextField
            variant="outlined"
            margin="normal"
            {...params}
            label="Período"
          />
        )}
        disableClearable
      />

      <Autocomplete
        style={{ width: '250px' }}
        value={productsSelecteds}
        onChange={handleChangeProducts}
        options={productsOptions}
        getOptionLabel={option => option.label}
        renderInput={params => (
          <TextField
            {...params}
            label="Produto"
            margin="normal"
            variant="outlined"
            inputProps={{ ...params.inputProps, readOnly: true }}
          />
        )}
        renderOption={props => (
          <>
            {loading ? (
              <CircularProgress />
            ) : (
              <div style={{ width: '100%' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Checkbox
                    checked={
                      productsSelecteds.findIndex(
                        item => item.value === props.value
                      ) >= 0
                    }
                  />
                  <ListItemText primary={props.label} />
                </div>
                {props.value === 'wl' && <Divider style={{ width: '100%' }} />}
              </div>
            )}
          </>
        )}
        getOptionSelected={(option, value) => option.value === value.value}
        renderTags={value => {
          return (
            <>
              {value.some(product => product.value === 'all') && (
                <span>Todos</span>
              )}
              {value.length === 1 && <span>{value[0].label}</span>}
              {value.length >= 2 &&
                !value.some(product => product.value === 'all') && (
                  <span>Vários produtos</span>
                )}
            </>
          );
        }}
        multiple
        disableClearable
        freeSolo={false}
      />

      <Autocomplete
        style={{ width: '250px' }}
        value={reasonsSelecteds}
        onChange={handleChangeReasons}
        options={reasonsOptions}
        getOptionLabel={option => option.label}
        renderInput={params => (
          <TextField
            {...params}
            label="Motivo"
            margin="normal"
            variant="outlined"
            inputProps={{ ...params.inputProps, readOnly: true }}
          />
        )}
        renderOption={props => (
          <>
            {loading ? (
              <CircularProgress />
            ) : (
              <div style={{ width: '100%' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Checkbox
                    checked={
                      reasonsSelecteds.findIndex(
                        item => item.value === props.value
                      ) >= 0
                    }
                  />
                  <S.CustomChip background={props.color}>
                    <span>{props.label}</span>
                  </S.CustomChip>
                </div>
                {props.value === 'all' && <Divider style={{ width: '100%' }} />}
              </div>
            )}
          </>
        )}
        getOptionSelected={(option, value) => option.value === value.value}
        renderTags={value => {
          return (
            <>
              {value.some(reason => reason.value === 'all') && (
                <span>Todos</span>
              )}
              {value.length === 1 && <span>{value[0].label}</span>}
              {value.length >= 2 &&
                !value.some(reason => reason.value === 'all') && (
                  <span>Vários motivos</span>
                )}
            </>
          );
        }}
        multiple
        disableClearable
        freeSolo={false}
      />

      <DisabledAccountsPeriodDialog
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
      />
    </S.Container>
  );
}
