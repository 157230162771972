import { get } from 'lodash';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Form } from '@unform/web';
import useUpdateEffect from '@restart/hooks/useUpdateEffect';
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import toFormdata from 'object-to-formdata';
import AwardsAutocomplete from '~/components/AwardsAutocomplete';
import api from '~/services/api';
import TextMaskCurrency from '~/components/masks/TextMaskCurrency';
import { TextMaskNumber } from '~/components/masks';
import { brlToNumber, decimalFormat } from '~/helpers/number';
import { toast } from 'react-toastify';
import Toast from '~/components/Toast';
import TextField from '~/components/unform/v2/InputText';
import { useSelector } from 'react-redux';
import DatePicker from '~/components/unform/v2/DatePicker';
import { add } from 'date-fns';
import Dialog from './Dialog';
import { useSweepstakeForm } from './SweepstakeFormProvider';
import FieldImage from '../../../../BannersHome/Form/ImageField';
import FieldArchive from '../../../../BannersHome/Form/ArchiveField';

import {
  PreviewContainer,
  Container,
  TwoColumnsField,
} from '../../../../BannersHome/Form/styles';
import { usePromotionContext } from '../../context';
import { FormError } from './styles';

function convertToInteger(str) {
  return parseInt(String(str).replace(/\D+/g, ''), 10) || 0;
}

const SweepstakeFormDialog = () => {
  const { closeForm, data, formOpened } = useSweepstakeForm();
  const [sweepstakeImage, setSweepstakeImage] = useState(null);
  const [changeAwards, setChangeAwards] = useState(false);
  const formRef = useRef();
  const [newSweepstakeImage, setNewSweepstakeImage] = useState(null);
  const [sweepstakeImageAward, setSweepstakeImageAward] = useState(null);
  const [newSweepstakeSheet, setNewSweepstakeSheet] = useState(null);
  const [isSheet, setIsSheet] = useState(false);
  const [drawType, setDrawType] = useState();
  const [allowQtdDelivery, setAllowQtdDelivery] = useState(false);
  const [classification, setClassification] = useState(null);
  const {
    onSave,
    setOnSave,
    promotion,
    replacePromotion,
    appendSweepstakeTypeFilter,
  } = usePromotionContext();

  const userProfile = useSelector(({ user }) => user.profile);

  const [sweepstakeSpecificConfig, setSweepstakeSpecificConfig] = useState();

  const [saving, setSaving] = useState(false);

  const [isNextStep, setIsNextStep] = useState(false);
  const [promotionDataStepOne, setPromotionDataStepOne] = useState({});
  const [promotionDataStepTwo, setPromotionDataStepTwo] = useState({});
  const [step, setStep] = useState(0);
  const [formErrors, setFormErrors] = useState({});
  const [order, setOrder] = useState();

  const SweepstakeSpecific = get(promotion, 'produto.id');

  const getSweepstakesSpecific = useCallback(async () => {
    if (SweepstakeSpecific !== undefined) {
      await api
        .get(`/promotions/config/${SweepstakeSpecific}?byProduct=true`)
        .then(res => {
          setSweepstakeSpecificConfig(res.data.config);
        });
    }
  }, [SweepstakeSpecific]);

  useEffect(() => {
    getSweepstakesSpecific();
  }, [getSweepstakesSpecific]);

  useEffect(() => {
    // Usado para salvar dados do Form na troca de step
    if (step === 0 && promotionDataStepOne && isNextStep) {
      formRef.current.setFieldValue(
        'description',
        promotionDataStepOne.description
      );
      formRef.current.setFieldValue(
        'serialNumber',
        promotionDataStepOne.serialNumber
      );
      formRef.current.setFieldValue(
        'suggestedValue',
        promotionDataStepOne.suggestedValue
      );
    }
    if (step === 1 && promotionDataStepTwo && isNextStep) {
      formRef.current.setFieldValue(
        'planNumber',
        promotionDataStepTwo.planNumber
      );
      formRef.current.setFieldValue(
        'quantityAwards',
        promotionDataStepTwo.quantityAwards
      );
      formRef.current.setFieldValue(
        'deliveryFrequency',
        promotionDataStepTwo.deliveryFrequency
      );
      formRef.current.setFieldValue(
        'qtdDelivery',
        promotionDataStepTwo.qtdDelivery
      );
      formRef.current.setFieldValue(
        'dataLoteria',
        promotionDataStepTwo.dataLoteria || null
      );
    }
  }, [step, promotionDataStepOne, promotionDataStepTwo, isNextStep]);

  const isNew = useMemo(() => {
    return !get(data, 'idSorteio', null);
  }, [data]);

  const isDraft = useMemo(() => {
    return data?.statusSorteio && data.statusSorteio === 3;
  }, [data]);

  const isCreatedByUser = useMemo(() => {
    return userProfile?._id === data?.createdBy?._id;
  }, [userProfile, data?.createdBy?._id]);

  const isDoubleCheckDone = useMemo(() => {
    return data?.doubleCheck;
  }, [data]);

  const isDoubleCheckedByUser = useMemo(() => {
    return data?.doubleCheckedBy?._id === userProfile?._id;
  }, [data, userProfile]);

  const isApprovePending = useMemo(() => {
    return isDoubleCheckDone && !isDoubleCheckedByUser && !isCreatedByUser;
  }, [isDoubleCheckDone, isDoubleCheckedByUser, isCreatedByUser]);

  const title = useMemo(() => {
    const id = get(data, 'idSorteio', null);
    if (isDraft) {
      if (isCreatedByUser) {
        return 'Editando rascunho de sorteio';
      }
      if (isApprovePending) {
        return 'Aprovando rascunho de sorteio';
      }
      return 'Conferindo rascunho de sorteio';
    }
    return isNew ? 'Novo sorteio' : `Editando sorteio #${id}`;
  }, [isNew, data, isCreatedByUser, isApprovePending, isDraft]);

  const initialQuantityAwards = useMemo(() => {
    if (isDraft && !isApprovePending && !isCreatedByUser) {
      return null;
    }

    return data?.qtdPremiosDisponiveis;
  }, [data, isDraft, isApprovePending, isCreatedByUser]);

  const initialDeliveryFrequency = useMemo(() => {
    if (isDraft && !isApprovePending && !isCreatedByUser) {
      return null;
    }

    return data?.qtdDesbloqueiaPremio;
  }, [data, isDraft, isApprovePending, isCreatedByUser]);

  const initialQtdDelivery = useMemo(() => {
    if (!allowQtdDelivery) {
      return 1;
    }
    if (isDraft && !isApprovePending && !isCreatedByUser) {
      return null;
    }
    return data?.qtdPremioEntrega;
  }, [data, isDraft, isApprovePending, isCreatedByUser, allowQtdDelivery]);

  const initialDataLoteria = useMemo(() => {
    if (isDraft) {
      return data?.dataLoteria;
    }

    if (isDraft && isCreatedByUser) {
      return data?.dataLoteria;
    }

    return add(new Date(data?.dataLoteria), { days: 1 });
  }, [data, isDraft, isApprovePending, isCreatedByUser]);

  const sweepstake = useMemo(
    () => ({
      id: get(data, 'idSorteio'),
      image: get(data, 'imagem.urlImagem'),
      description: get(data, 'descricao'),
      suggestedValue:
        isApprovePending || isCreatedByUser
          ? decimalFormat(
              isCreatedByUser
                ? data.valorSugerido.$numberDecimal / initialQtdDelivery
                : data.valorSugerido.$numberDecimal
            )
          : decimalFormat(get(data, 'valorSugerido')),
      type: get(data, 'tipo.idTipoSorteio'),
      order: get(data, 'ordem'),
      status: get(data, 'status'),
      code: get(data, 'codigo'),
      serialNumber: get(data, 'numeroSerie') || null,
      planNumber: get(data, 'numeroPlano') || null,
      quantityAwards: initialQuantityAwards,
      deliveryFrequency: initialDeliveryFrequency,
      classification: get(data, 'classificacao'),
      qtdDelivery: initialQtdDelivery,
      dataLoteria: initialDataLoteria || null,
    }),
    [
      data,
      initialDeliveryFrequency,
      initialQuantityAwards,
      initialQtdDelivery,
      isApprovePending,
      isCreatedByUser,
    ]
  );

  useEffect(() => {
    if ((!isNew && !isDraft) || isApprovePending || isCreatedByUser) {
      setDrawType(sweepstake.type);
    }

    if (!isNew || isDraft) {
      setClassification(sweepstake.classification);
      setOrder(+sweepstake.order);
    }

    const {
      image,
      description,
      status,
      code,
      serialNumber,
      planNumber,
      quantityAwards,
      deliveryFrequency,
      suggestedValue,
      type,
      qtdDelivery,
      dataLoteria,
    } = sweepstake;

    const updatedPromotionDataStepOne = {
      image,
      description,
      order,
      status,
      code,
      serialNumber,
      classification,
      ...(!isNew ? { suggestedValue, type } : {}),
    };

    const updatedPromotionDataStepTwo = {
      planNumber,
      qtdDelivery,
      dataLoteria,
      ...(!isNew ? { quantityAwards, deliveryFrequency } : {}),
    };

    if (!isNew || isDraft) {
      setPromotionDataStepOne(updatedPromotionDataStepOne);
      setPromotionDataStepTwo(updatedPromotionDataStepTwo);

      if (data?.imagemPremio?.urlImagem) {
        setSweepstakeImageAward(data.imagemPremio.urlImagem);
      }
    }
  }, [
    sweepstake,
    isNew,
    data?.imagemPremio?.urlImagem,
    data,
    isDraft,
    isApprovePending,
  ]);

  useEffect(() => {
    setIsNextStep(false);
    setStep(0);
  }, []);

  const handleClose = useCallback(() => {
    setSweepstakeImage(null);
    setNewSweepstakeImage(null);
    setSweepstakeImageAward(null);
    setChangeAwards(false);
    setStep(0);
    setIsNextStep(false);
    setDrawType(null);
    setClassification(null);
    setPromotionDataStepOne({});
    setPromotionDataStepTwo({});
    setNewSweepstakeSheet(null);
    closeForm();
    setOrder(null);
  }, [closeForm]);

  const handleReturn = useCallback(() => {
    setStep(0);
    const formData = formRef.current.getData();
    setPromotionDataStepTwo(formData);
  }, [formRef]);

  const handleSaveSweepstake = useCallback(
    async formData => {
      const mergedData = {
        ...promotionDataStepOne,
        ...promotionDataStepTwo,
        ...formData,
        classification,
        order,
      };

      const image = newSweepstakeImage || sweepstakeImage;
      const imageAward = sweepstakeImageAward;
      const capitalizingNumbersSheet = newSweepstakeSheet || null;

      if (
        isNextStep &&
        (mergedData.deliveryFrequency === '0' || !mergedData.deliveryFrequency)
      ) {
        return toast.error(
          <Toast
            message="A Frequência de entregas não pode ser zero ou vazia"
            error
          />
        );
      }

      if (!isDraft && isNew && isSheet && !capitalizingNumbersSheet) {
        return toast.error(
          <Toast
            message="Planilha com números da capitalizadora não informada"
            error
          />
        );
      }

      if (
        !isDraft &&
        isNextStep &&
        isSheet &&
        (mergedData.quantityAwards === '0' || !mergedData.quantityAwards)
      ) {
        return toast.error(
          <Toast
            message="A Quantidade de prêmios não pode ser zero ou vazia"
            error
          />
        );
      }

      if (!isDraft && isNextStep && !imageAward) {
        return toast.error(
          <Toast message="A imagem do prêmio é obrigatória" error />
        );
      }

      if (!isDraft && isNextStep && isSheet && !mergedData.classification) {
        return toast.error(
          <Toast message="A classificação é obrigatória" error />
        );
      }

      if (
        isNextStep &&
        allowQtdDelivery &&
        (mergedData.qtdDelivery === '0' || !mergedData.qtdDelivery)
      ) {
        return toast.error(
          <Toast
            message="A quantidade de entrega não pode ser zero ou vazia"
            error
          />
        );
      }

      if (isNextStep && promotion.instantanea && !mergedData.dataLoteria) {
        return toast.error(
          <Toast message="A data da loteria não pode ser vazia" error />
        );
      }

      const cleanedData = Object.fromEntries(
        Object.entries(mergedData).filter(([, value]) => {
          if (isNew || isDraft) {
            return value !== null && value !== '';
          }
          return value !== null;
        })
      );

      let suggestedValue = brlToNumber(
        mergedData.suggestedValue.replace('R$ ', '')
      );

      if (isNew) {
        suggestedValue *= cleanedData.qtdDelivery || 1;
      }

      const newData = toFormdata({
        ...cleanedData,
        image,
        imageAward,
        capitalizingNumbersSheet,
        suggestedValue: parseFloat(suggestedValue).toPrecision(4),
        serialNumber: convertToInteger(mergedData.serialNumber),
        type: drawType,
      });

      setSaving(true);

      if (isDraft) {
        try {
          const payload = {
            ...cleanedData,
            capitalizingNumbersSheet,
            image,
            imageAward,
            suggestedValue: brlToNumber(
              mergedData.suggestedValue.replace('R$ ', '')
            ),
            serialNumber: convertToInteger(mergedData.serialNumber),
            type: drawType,
            draftId: data?._id,
          };

          if (payload.dataLoteria === undefined) {
            payload.dataLoteria = null;
          }

          if (payload.qtdDelivery === undefined) {
            payload.qtdDelivery = 1;
          }

          payload.suggestedValue *= payload.qtdDelivery || 1;
          payload.suggestedValue = parseFloat(
            payload.suggestedValue
          ).toPrecision(4);

          if (isCreatedByUser) {
            try {
              const { data: response } = await api.put(
                `/promotions/${data._id}/sweepstakes/edit-double-check`,
                payload
              );

              const promotionSweepstakeDraft = promotion.promotionSweepstakeDraft.map(
                swptk =>
                  swptk._id === response.sweepstake._id
                    ? response.sweepstake
                    : swptk
              );
              const newPromotion = {
                ...promotion,
                promotionSweepstakeDraft,
              };
              const sweepstakeType = response.sweepstake?.tipo?.codigo;
              appendSweepstakeTypeFilter(sweepstakeType);
              replacePromotion(newPromotion);
              setOnSave(false);
              handleClose();
              return true;
            } catch (error) {
              return false;
            }
          }

          if (isApprovePending) {
            try {
              const { data: response } = await api.post(
                `/promotions/${data._id}/sweepstakes/approve-double-check`,
                payload
              );

              const newSweepstakeOrder = response.newSweepstake.ordem - 1;
              const newPromotion = {
                ...promotion,
                promotionSweepstakeDraft: [
                  ...promotion.promotionSweepstakeDraft.filter(
                    swpstk => swpstk._id !== response.newSweepstake._id
                  ),
                ],
                sorteios: [
                  ...promotion.sorteios.slice(0, newSweepstakeOrder),
                  response.newSweepstake,
                  ...promotion.sorteios.slice(newSweepstakeOrder),
                ],
              };

              const sweepstakeType = response.newSweepstake?.tipo?.codigo;
              appendSweepstakeTypeFilter(sweepstakeType);
              replacePromotion(newPromotion);
              setOnSave(false);
              handleClose();
              toast.success(<Toast message="Rascunho aprovado com sucesso" />);
              return true;
            } catch (error) {
              return false;
            }
          }

          const { data: response } = await api.post(
            `/promotions/${promotion.idPromocao}/sweepstakes/double-check`,
            payload
          );

          if (!response.success) {
            const errorMessages = Object.fromEntries(
              Object.entries(response.divergentFields).map(([field]) => [
                field,
                'Valor divergente',
              ])
            );

            toast.error(
              <Toast
                message="Falha ao validar campos, confira novamente."
                error
              />
            );

            setFormErrors(errorMessages);
            formRef.current.setErrors(errorMessages);
            return false;
          }
          const promotionSweepstakeDraft = promotion.promotionSweepstakeDraft.map(
            swptk =>
              swptk._id === response.newSweepstake._id
                ? response.newSweepstake
                : swptk
          );
          const newPromotion = {
            ...promotion,
            promotionSweepstakeDraft,
          };

          const sweepstakeType = response.newSweepstake?.tipo?.codigo;
          appendSweepstakeTypeFilter(sweepstakeType);
          replacePromotion(newPromotion);
          setOnSave(false);
          handleClose();
          return true;
        } catch (error) {
          return false;
        } finally {
          setSaving(false);
        }
      }

      const requestUrl = `/promotions/${promotion.idPromocao}/sweepstakes`;

      const request = (...args) =>
        isNew ? api.post(...args) : api.put(...args);
      try {
        const { data: res } = await request(
          isNew ? requestUrl : requestUrl.concat(`/${sweepstake.id}`),
          newData
        );

        if (isNew) {
          const firstRes = res[0];
          const isDoubleCheckRequired = firstRes?.statusSorteio === 3;
          const newSweepstakeOrder = isDoubleCheckRequired
            ? 0
            : (firstRes?.ordem ?? 1) - 1;

          if (isDoubleCheckRequired) {
            firstRes.valorSugerido = +firstRes.valorSugerido.$numberDecimal;
          }

          const newPromotion = {
            ...promotion,
            sorteios: [
              ...promotion.sorteios.slice(0, newSweepstakeOrder),
              firstRes,
              ...promotion.sorteios.slice(newSweepstakeOrder),
            ],
          };
          const type = res?.tipo?.codigo;
          appendSweepstakeTypeFilter(type);
          replacePromotion(newPromotion);
          setOnSave(!onSave);
        } else {
          const newSweepstakeOrder = (res?.ordem || 1) - 1;
          const newPromotion = {
            ...promotion,
            ...(res.statusSorteio === 3
              ? {
                  promotionSweepstakeDraft: [
                    ...promotion.promotionSweepstakeDraft,
                    res,
                  ],
                }
              : {}),
            sorteios: [
              ...promotion.sorteios.slice(0, newSweepstakeOrder),
              res,
              ...promotion.sorteios.slice(newSweepstakeOrder + 1),
            ],
          };
          replacePromotion(newPromotion);
        }
        handleClose();
      } catch (err) {
        console.error(err); // eslint-disable-line
      } finally {
        setSaving(false);
      }

      return true;
    },
    [
      onSave,
      setOnSave,
      sweepstakeImage,
      newSweepstakeImage,
      isNew,
      promotion,
      handleClose,
      replacePromotion,
      setSaving,
      sweepstake.id,
      appendSweepstakeTypeFilter,
      isNextStep,
      promotionDataStepOne,
      promotionDataStepTwo,
      newSweepstakeSheet,
      step,
      sweepstakeImageAward,
      order,
      classification,
      drawType,
      allowQtdDelivery,
    ]
  );

  const defineTypeStates = useCallback(type => {
    const nextStepCases = [6, 8, 9, 10, 12];
    const nextStep = nextStepCases.includes(type);
    const sheet = type === 6 || type === 8;

    setIsSheet(sheet);
    setAllowQtdDelivery(!sheet);
    setIsNextStep(nextStep);
  }, []);

  const calculateNextOrder = useCallback(
    evt => {
      const type = evt?.target?.value;

      if (!type || Number.isNaN(Number(type))) return;

      setDrawType(+type);
      defineTypeStates(+type);

      const allSweepstakes = promotion?.sorteios || [];

      const lastOfType = { nextOrder: null, nextTypeToFound: +type };

      while (lastOfType.nextOrder === null) {
        const allSweepstakeOfType = allSweepstakes.filter(
          item => item?.tipo?.idTipoSorteio === lastOfType.nextTypeToFound
        );

        const itemFound =
          allSweepstakeOfType.length > 0
            ? allSweepstakeOfType[allSweepstakeOfType.length - 1]
            : null;

        if (!itemFound && lastOfType.nextTypeToFound === 1) {
          lastOfType.nextOrder = 0;
          break;
        } else if (!itemFound) {
          lastOfType.nextTypeToFound -= 1;
        } else {
          lastOfType.nextOrder = itemFound.ordem;
          break;
        }
      }

      setOrder(lastOfType.nextOrder + 1);
    },
    [promotion, setOrder, setDrawType, defineTypeStates]
  );

  const updateClassification = useCallback(evt => {
    const classificationType = get(evt, 'target.value');
    setClassification(classificationType);
  }, []);

  useEffect(() => {
    if (sweepstake.type) {
      defineTypeStates(sweepstake.type);
    }
  }, [sweepstake]);

  useUpdateEffect(() => {
    if (sweepstake.image) {
      setSweepstakeImage(sweepstake.image);
    }
  }, [sweepstake]);

  const okText = useMemo(() => {
    if (isDraft) {
      if (isCreatedByUser) return 'Editar';
      if (!isCreatedByUser && !isDoubleCheckDone) return 'Conferir';
      if (!isCreatedByUser && !isDoubleCheckedByUser && isDoubleCheckDone)
        return 'Aprovar';
    }

    return 'Salvar';
  }, [isCreatedByUser, isDoubleCheckDone, isDoubleCheckedByUser]);

  if (!formOpened) return null;

  const handleGetFormData = () => {
    setStep(1);
    const formData = formRef.current.getData();
    setPromotionDataStepOne({ ...formData, order, classification });
  };

  return (
    <Dialog
      title={title}
      onCancel={handleClose}
      onReturn={handleReturn}
      isReturn={step === 1}
      formId="sweepstake-form"
      loading={saving}
      okText={okText}
    >
      <Container>
        <Form
          onSubmit={handleSaveSweepstake}
          initialData={sweepstake}
          ref={formRef}
          id="sweepstake-form"
        >
          {step === 0 && (
            <div style={{ display: 'flex' }}>
              <PreviewContainer>
                <FieldImage
                  src={
                    newSweepstakeImage && typeof newSweepstakeImage === 'object'
                      ? URL.createObjectURL(newSweepstakeImage)
                      : sweepstakeImage
                  }
                  onChange={image => {
                    setNewSweepstakeImage(image);
                  }}
                  disabled={isDraft || isApprovePending}
                />
              </PreviewContainer>

              <div style={{ marginLeft: 15, width: '100%' }}>
                {changeAwards && (
                  <>
                    <AwardsAutocomplete
                      margin="none"
                      valueKey="key"
                      product={get(promotion, 'produto.id')}
                      onChange={evt => {
                        const award = evt.target.value;
                        const image = award.imagemSorteio;
                        setSweepstakeImage(image);
                        setChangeAwards(false);
                        setNewSweepstakeImage(null);
                      }}
                    />
                    <div
                      style={{
                        height: 2,
                        marginTop: 10,
                        marginBottom: 15,
                        borderBottom: '1px dotted rgba(0, 0, 0, 0.4)',
                      }}
                    />
                  </>
                )}
                <TextField
                  name="description"
                  label="Descrição"
                  fullWidth
                  variant="outlined"
                  disabled={(isDraft && !isCreatedByUser) || isApprovePending}
                />
                <TextField
                  style={{ marginTop: 15 }}
                  name="serialNumber"
                  label="Número Série"
                  fullWidth
                  variant="outlined"
                  InputProps={{
                    inputComponent: TextMaskNumber,
                  }}
                  disabled={(isDraft && !isCreatedByUser) || isApprovePending}
                />
                {!isDraft && (
                  <Button
                    size="small"
                    variant="text"
                    color="primary"
                    onClick={() => setChangeAwards(oldState => !oldState)}
                    disableRipple
                    disableFocusRipple
                  >
                    {!sweepstake.description
                      ? 'Selecionar prêmio'
                      : 'Trocar prêmio'}
                  </Button>
                )}

                <TextField
                  name="suggestedValue"
                  label="Valor sugerido"
                  variant="outlined"
                  style={{ marginBottom: 15, marginTop: 15 }}
                  InputProps={{
                    inputComponent: TextMaskCurrency,
                  }}
                  disabled={(!isNew && isNextStep) || isApprovePending}
                />
                <TwoColumnsField>
                  <FormControl
                    variant="outlined"
                    fullWidth
                    error={!!formErrors.type}
                    disabled={(!isNew && !!sweepstake.id) || isApprovePending}
                  >
                    <InputLabel id="type">Tipo do Sorteio</InputLabel>
                    <Select
                      name="type"
                      label="Tipo do Sorteio"
                      variant="outlined"
                      readOnly={(!isNew && !!sweepstake.id) || isApprovePending}
                      value={drawType}
                      onChange={event => {
                        return isDraft
                          ? setDrawType(+event.target.value)
                          : calculateNextOrder(event);
                      }}
                    >
                      {sweepstakeSpecificConfig?.tipoSorteio?.map(type => (
                        <MenuItem key={type.id} value={type.id}>
                          {type.descricao}
                        </MenuItem>
                      ))}
                    </Select>
                    {formErrors.type && <FormError>Valor divergente</FormError>}
                  </FormControl>

                  <TextField
                    name="order"
                    label="Ordem"
                    value={order}
                    onChange={e => setOrder(+e.target.value)}
                    variant="outlined"
                    disabled={(isDraft && !isCreatedByUser) || isApprovePending}
                  />
                </TwoColumnsField>
                {promotion.instantanea && (
                  <FormControl
                    variant="outlined"
                    fullWidth
                    disabled={(isDraft && !isCreatedByUser) || isApprovePending}
                  >
                    <InputLabel id="classification">Classificação</InputLabel>
                    <Select
                      name="classification"
                      label="Classificação"
                      variant="outlined"
                      value={
                        classification ? String(classification) : classification
                      }
                      onChange={updateClassification}
                    >
                      <MenuItem value="0">Comum</MenuItem>
                      <MenuItem value="1">Especial</MenuItem>
                      <MenuItem value="2">Acumulado</MenuItem>
                    </Select>
                  </FormControl>
                )}
                {isNextStep && (
                  <Button
                    size="small"
                    variant="text"
                    color="primary"
                    style={{ marginBottom: 15 }}
                    onClick={handleGetFormData}
                    disableRipple
                    disableFocusRipple
                  >
                    {isApprovePending ? 'Ver detalhes' : 'Editar detalhes'}
                  </Button>
                )}
              </div>
            </div>
          )}
          {step === 1 && (
            <div style={{ display: 'flex' }}>
              <PreviewContainer>
                <FieldImage
                  isBalloon
                  src={
                    sweepstakeImageAward &&
                    typeof sweepstakeImageAward === 'object'
                      ? URL.createObjectURL(sweepstakeImageAward)
                      : sweepstakeImageAward
                  }
                  onChange={image => {
                    setSweepstakeImageAward(image);
                  }}
                  disabled={isDraft || isApprovePending}
                />
              </PreviewContainer>
              <div style={{ marginLeft: 15, width: '100%' }}>
                <TextField
                  style={{ marginBottom: 15 }}
                  name="planNumber"
                  label="Número plano"
                  fullWidth
                  variant="outlined"
                  type="number"
                  disabled={(isDraft && !isCreatedByUser) || isApprovePending}
                />

                {promotion.instantanea && (
                  <DatePicker
                    name="dataLoteria"
                    label="Data Loteria"
                    disabled={(isDraft && !isCreatedByUser) || isApprovePending}
                  />
                )}

                <TextField
                  style={{ marginTop: 15 }}
                  name="quantityAwards"
                  label="Quantidade de prêmios"
                  fullWidth
                  variant="outlined"
                  type="number"
                  disabled={!isNew || isApprovePending}
                />
                <TextField
                  style={{ marginTop: 15 }}
                  name="deliveryFrequency"
                  label="Frequência de entrega"
                  fullWidth
                  variant="outlined"
                  type="number"
                  disabled={isApprovePending}
                />

                {allowQtdDelivery && (
                  <TextField
                    style={{ marginTop: 15 }}
                    name="qtdDelivery"
                    label="Quantidade de Entrega"
                    fullWidth
                    variant="outlined"
                    type="number"
                    isPositiveNumber
                    defaultValue={isNew ? 1 : undefined}
                    disabled={!isNew || isApprovePending}
                  />
                )}

                {isSheet && isNew && !isDraft && (
                  <div style={{ display: 'flex', marginTop: 15 }}>
                    <FieldArchive
                      src={newSweepstakeSheet}
                      onChange={e => {
                        setNewSweepstakeSheet(e);
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          )}
        </Form>
      </Container>
    </Dialog>
  );
};

export default SweepstakeFormDialog;
