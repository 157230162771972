export default [
  {
    title: 'Pesquisar',
    icon: 'search',
    router: '/person/search',
    permission: ['read_persons', 'support_reset_password'],
  },
  {
    title: 'Produto por Região',
    icon: 'FaMap',
    router: '/products',
    permission: 'read_products',
  },
  {
    title: 'Triagem Ganhador',
    icon: 'FaHourglassEnd',
    router: '/screenings',
    permission: 'read_screenings',
  },
  {
    title: 'Feedbacks',
    icon: 'FaBullhorn',
    router: '/feedbacks',
    permission: 'read_winners_feedback',
  },
  {
    title: 'Resultados',
    icon: 'stars',
    router: '/promotions/results',
    permission: 'read_promotions',
  },
  {
    title: 'Notificações Push',
    icon: 'FaBell',
    router: '/push-notifications',
    permission: 'read_pushs',
  },
  {
    title: 'Atividades',
    icon: 'menu_book',
    router: '/activities',
    permission: 'read_activities',
  },
  {
    title: 'Financeiro',
    icon: 'MdReceiptLong',
    router: '/financial',
    permission: 'read_promotions',
  },
  {
    title: 'Contestações',
    icon: 'block',
    router: '/constestation',
    permission: 'read_contestation',
  },
  {
    title: 'FAQ',
    icon: 'MdForum',
    router: '/faq',
    permission: 'read_faq',
  },
  {
    divider: true,
  },
  {
    title: 'Perfil',
    icon: 'lock',
    router: '/roles',
    permission: 'read_roles',
  },

  {
    title: 'Usuários',
    icon: 'person',
    router: '/users',
    permission: 'read_users',
  },
  {
    divider: true,
  },
  {
    title: 'CG Susep',
    icon: 'IoMdPaper',
    router: '/cgs-susep',
    permission: 'read_cgs_susep',
  },
  {
    title: 'Banners',
    icon: 'FaImages',
    router: '/banners-home',
    permission: 'read_banners_home',
  },
  {
    title: 'Rascunhos',
    icon: 'FaCalendarAlt',
    router: '/promotion/drafts',
    permission: 'read_promotion_drafts',
  },
  {
    title: 'Promoções Vigentes',
    icon: 'FaCalendarCheck',
    router: '/promotions/all',
    permission: 'read_promotions',
  },
  {
    title: 'Config',
    icon: 'FaCog',
    router: '/promotion-config',
    permission: 'read_promotion_config',
  },
  {
    title: 'Código Promocional',
    icon: 'FaUsersSlash',
    router: '/promotion-codes',
    permission: 'read_promotion_codes',
  },
  {
    title: 'Encerramento de contas',
    icon: 'MdPersonRemoveAlt1',
    router: '/disabled-accounts',
    permission: 'read_disabled_accounts',
  },
];
