import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import api from '~/services/api';
import { useDebounce } from './useDebounce';

const DisabledAccountsContext = createContext({});

const DisabledAccountsProvider = ({ children }) => {
  const [applications, setApplications] = useState([]);
  const [reasons, setReasons] = useState([]);
  const [disabledAccounts, setDisabledAccounts] = useState([]);

  const [productsSelecteds, setProductsSelecteds] = useState([]);
  const [reasonsSelecteds, setReasonsSelecteds] = useState([]);
  const [personId, setPersonId] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [totalPages, setTotalPages] = useState('');
  const [page, setPage] = useState(1);

  const [loading, setLoading] = useState(true);
  const [loadingDocs, setLoadingDocs] = useState(false);

  const personIdDebounced = useDebounce(personId);

  const findAllApplicationsActives = useCallback(async () => {
    try {
      setLoading(true);

      const { data } = await api.get('/disabled-accounts/applications');

      setApplications(data.docs);
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }, []);

  const findAllReasonsActives = useCallback(async () => {
    try {
      setLoading(true);

      const { data } = await api.get('/disabled-accounts/reasons');

      setReasons(data.docs);
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }, []);

  const findAllDisabledAccounts = useCallback(
    async pageChanged => {
      try {
        setLoadingDocs(true);
        const productsFilter = productsSelecteds
          .map(product => product.value)
          .join(',');

        const reasonsFilter = reasonsSelecteds
          .map(reason => reason.value)
          .join(',');

        const { data } = await api.get(
          `/disabled-accounts?personId=${personIdDebounced}&startDate=${startDate}&endDate=${endDate}&products=${productsFilter}&reasons=${reasonsFilter}&page=${page}&limit=10`
        );

        setTotalPages(data.pages);

        if (disabledAccounts && disabledAccounts.length && pageChanged) {
          setDisabledAccounts(prev => [...prev, ...data.docs]);
        } else {
          setDisabledAccounts(data.docs);
        }
      } catch (error) {
        setLoadingDocs(false);
      } finally {
        setLoadingDocs(false);
      }
    },
    [
      productsSelecteds,
      reasonsSelecteds,
      personIdDebounced,
      startDate,
      endDate,
      page,
    ]
  );

  useEffect(() => {
    findAllApplicationsActives();
  }, []);

  useEffect(() => {
    findAllReasonsActives();
  }, []);

  useEffect(() => {
    setPage(1);
    setDisabledAccounts([]);
    findAllDisabledAccounts();
  }, [
    productsSelecteds,
    reasonsSelecteds,
    personIdDebounced,
    startDate,
    endDate,
  ]);

  useEffect(() => {
    findAllDisabledAccounts(true);
  }, [page]);

  return (
    <DisabledAccountsContext.Provider
      value={{
        applications,
        reasons,
        disabledAccounts,
        productsSelecteds,
        setProductsSelecteds,
        reasonsSelecteds,
        setReasonsSelecteds,
        setPersonId,
        setStartDate,
        setEndDate,
        totalPages,
        setPage,
        page,
        loading,
        loadingDocs,
      }}
    >
      {children}
    </DisabledAccountsContext.Provider>
  );
};

DisabledAccountsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

const useDisabledAccounts = () => {
  const context = useContext(DisabledAccountsContext);
  return context;
};

export { DisabledAccountsProvider, useDisabledAccounts };
