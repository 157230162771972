import React, { useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { IDatePicker } from '~/components';
import { isValid, format } from 'date-fns';
import { useDisabledAccounts } from '../../hooks/useDisabledAccounts';

import * as S from './styles';

export default function DisabledAccountsPeriodDialog({
  openDialog,
  setOpenDialog,
}) {
  const { setStartDate, setEndDate } = useDisabledAccounts();

  const [firstDate, setFirstDate] = useState(null);
  const [lastDate, setLastDate] = useState(null);

  const handleSubmit = () => {
    setStartDate(firstDate);
    setEndDate(lastDate);
    setOpenDialog(false);
  };

  return (
    <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
      <DialogTitle>Período personalizado</DialogTitle>
      <DialogContent
        style={{ display: 'flex', gap: '2rem', flexDirection: 'column' }}
      >
        <S.DialogContainer>
          <IDatePicker
            id="firstDate"
            label="DD/MM/AAAA"
            onChange={value => {
              const formated =
                value && isValid(value)
                  ? `${format(value, 'yyyy-MM-dd')} 00:00:00`
                  : '';
              setFirstDate(formated);
            }}
            value={firstDate}
          />
          <IDatePicker
            id="lastDate"
            label="DD/MM/AAAA"
            onChange={value => {
              const formated =
                value && isValid(value)
                  ? `${format(value, 'yyyy-MM-dd')} 23:59:59`
                  : '';
              setLastDate(formated);
            }}
            value={lastDate}
          />
        </S.DialogContainer>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setOpenDialog(false);
            setFirstDate(null);
            setLastDate(null);
          }}
          variant="outlined"
          color="primary"
        >
          Cancelar
        </Button>
        <Button
          disabled={!firstDate || !lastDate}
          onClick={() => {
            handleSubmit();
          }}
          variant="contained"
          color="primary"
        >
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

DisabledAccountsPeriodDialog.propTypes = {
  openDialog: PropTypes.bool.isRequired,
  setOpenDialog: PropTypes.func.isRequired,
};
