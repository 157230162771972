import React from 'react';

import * as S from './styles';

export default function DisabledAccountsTableHead() {
  return (
    <S.TableHead>
      <S.TableRow>
        <th>Nº APCAP</th>
        <th>Produto</th>
        <th>Encerrado em</th>
        <th>Motivo</th>
      </S.TableRow>
    </S.TableHead>
  );
}
