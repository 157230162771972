import styled from 'styled-components';

export const Container = styled.div`
  margin-bottom: 32px;
`;

export const Title = styled.h2`
  font-size: 28px;
  color: #000000;
  font-weight: bold;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  background-color: white;
  align-items: center;
  width: 100%;
  margin-top: 24px;
  padding: 16px 0px 8px 0px;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12);
`;
