import {
  TableHead as MTableHead,
  TableRow as MTableRow,
  Typography,
} from '@material-ui/core';

import styled from 'styled-components';

export const TableHead = styled(MTableHead)`
  background-color: #3f51b5;
  height: 48px;
  color: white;
`;

export const TableRow = styled(MTableRow)`
  text-align: left;
  border-bottom: 1px solid #e4e4e4;

  th {
    width: auto;
    padding: 4px;
    font-size: 1rem;
    padding-left: 18px;
  }

  td {
    width: auto;
    padding: 20px 0px;
    font-size: 1rem;
    padding-left: 18px;
  }

  :hover {
    background-color: '#e4e4e4';
  }
`;

export const ApcapNumber = styled.span`
  font-weight: 600;
  color: #3f51b5;

  :hover {
    cursor: pointer;
  }
`;

export const ContainerRow = styled.div`
  display: flex;
  align-items: center;
`;

export const CustomChip = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  padding: 2px 8px;
  background: ${({ background }) => background ?? 'transparent'};
  border-radius: 34px;
`;

export const InfoButton = styled.button`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  outline: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Center = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ContainerPopover = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 312px;
  padding: 16px;
  gap: 12px;
`;

export const LabelCopy = styled(Typography)`
  text-transform: uppercase;
  color: #3f51b5;
  margin-left: 8px;
`;

export const ContainerApplication = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const ApplicationLabel = styled(Typography)`
  font-weight: bold !important;
  color: #49454f;
`;

export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const RowContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 42px;
`;
