/* eslint-disable func-names */
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Checkbox,
  Grid,
  Button,
  Chip,
  Avatar,
  CircularProgress,
  Icon,
  DialogActions,
} from '@material-ui/core';
import * as Yup from 'yup';
import { TextField } from 'unform-material-ui';
import { Form } from '@rocketseat/unform';
import { FcCheckmark } from 'react-icons/fc';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { format } from 'date-fns';
import { hasPermission } from '~/components/AccessControl';
import { AccessControl } from '~/components';
import api from '~/services/api';
import { toast } from 'react-toastify';
import Toast from '~/components/Toast';
import { store } from '~/store';
import PermissionsSelect from '../components/permissionsSelect';
import ProductsSelect from '../components/productsSelect';
import { backgroundColorRole } from '../components/backgroundColorRole';
import { Roles, LabelRules, Container } from './styles';

const schema = Yup.object().shape({
  firstName: Yup.string()
    .min(5, 'O nome deve ter ao menos 5 caracteres')
    .required('Campo obrigatório'),
  lastName: Yup.string()
    .min(5, 'O sobrenome deve ter ao menos 5 caracteres')
    .required('Campo obrigatório'),
  email: Yup.string()
    .email('Informe um e-mail válido')
    .required('Campo obrigatório'),
  password: Yup.string().when(['$id'], function(id) {
    if (!id) {
      return this.required('A senha é obrigatória');
    }
    return this.optional();
  }),
  permissions: Yup.string(),
});

export default function DialogNewUser({
  user,
  open,
  onClose,
  setRefetch,
  refetchState,
}) {
  const [userApi, setUserApi] = useState(false);
  const [formUserApi, setFormUserApi] = useState(false);
  const [roles, setRoles] = useState([]);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [qrCodeHasBeenReseted, setQrCodeHasBeenReseted] = useState(false);
  const [ifMinCharacters, setIfMinCharacters] = useState(false);
  const [ifNumber, setIfNumber] = useState(false);
  const [ifLowerCase, setIfLowerCase] = useState(false);
  const [ifUpperCase, setIfUpperCase] = useState(false);
  const [ifSpecialCharacter, setIfSpecialCharacter] = useState(false);
  const [passwordValue, setPasswordValue] = useState('');

  const { token } = store.getState().auth;

  const setHasUserProvided = () => {
    if (user?.clientRoles) {
      const userRoles = user.clientRoles.map(clientRole => {
        return {
          roleId: clientRole.id,
          roleAttributes: {
            roleName: clientRole.name,
          },
        };
      });
      setRoles(userRoles);
    }
    if (user?.attributes?.productPermissions) {
      setProducts(user?.attributes?.productPermissions);
    }
  };

  useEffect(() => {
    if (!isEmpty(user.id)) {
      setFormUserApi(
        user?.attributes?.allowApiUsage &&
          JSON.parse(user?.attributes?.allowApiUsage?.toLowerCase())
      );

      setUserApi(
        user?.attributes?.allowApiUsage &&
          JSON.parse(user?.attributes?.allowApiUsage?.toLowerCase())
      );

      setHasUserProvided();
    } else {
      setRoles([]);
    }
  }, [user]);

  const userId = useMemo(() => user.id, [user]);

  const userHasQrCode = useMemo(
    () => !isEmpty(user) && !isEmpty(user?.attributes?.dateQrcodeRead),
    [user]
  );

  const dateUserReadQrCode = useMemo(() => {
    return userHasQrCode && !qrCodeHasBeenReseted
      ? format(new Date(user?.attributes?.dateQrcodeRead), 'dd/MM/yyyy HH:mm')
      : 'n/a';
  }, [user, qrCodeHasBeenReseted]);

  // eslint-disable-next-line consistent-return
  const handleResetQrCode = useCallback(async () => {
    try {
      setLoading(true);
      if (hasPermission('update_user') && userId) {
        await api.put(
          `/users/reset-qrcode`,
          { ...user, roles: user.clientRoles },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      }
    } finally {
      setLoading(false);
      onClose();
      setQrCodeHasBeenReseted(true);
      setRefetch(!refetchState);
    }
  }, [user]);

  const handleCheck = () => {
    setFormUserApi(!formUserApi);
  };

  // eslint-disable-next-line consistent-return
  const handleSubmit = async dataUpdate => {
    const { firstName, lastName, email, password } = dataUpdate;

    const productsSelecteds = products.map(({ id }) => {
      return id;
    });
    const rolesSelecteds = roles.map(role => {
      return {
        id: role.roleId,
        name: role.roleAttributes.roleName,
      };
    });

    if (
      passwordValue &&
      (!ifLowerCase ||
        !ifMinCharacters ||
        !ifNumber ||
        !ifSpecialCharacter ||
        !ifUpperCase)
    ) {
      return toast.error(
        <Toast message="A senha não atende aos critérios" error />
      );
    }

    try {
      setLoading(true);
      const verb = user?.id ? 'put' : 'post';

      if (
        (hasPermission('create_user') && verb === 'post') ||
        (hasPermission('update_user') && verb === 'put')
      ) {
        await api[verb](
          `/users/${user.id || ''}`,
          {
            firstName,
            lastName,
            email,
            password,
            allowApiUsage: formUserApi,
            roles: rolesSelecteds,
            productPermissions: productsSelecteds,
          },
          { headers: { Authorization: `Bearer ${token}` } }
        );
      }
    } finally {
      setLoading(false);
      onClose();
      setRefetch(!refetchState);
    }
  };

  const handleChangePassword = event => {
    const { target } = event;
    setPasswordValue(target.value);

    if (target.value.length >= 12) {
      setIfMinCharacters(true);
    } else {
      setIfMinCharacters(false);
    }

    if (/[A-Z]/.test(target.value)) {
      setIfUpperCase(true);
    } else {
      setIfUpperCase(false);
    }

    if (/[a-z]/.test(target.value)) {
      setIfLowerCase(true);
    } else {
      setIfLowerCase(false);
    }

    if (/\d/.test(target.value)) {
      setIfNumber(true);
    } else {
      setIfNumber(false);
    }

    if (/[\W_]/.test(target.value)) {
      setIfSpecialCharacter(true);
    } else {
      setIfSpecialCharacter(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <Form
        schema={schema}
        autoComplete="off"
        autoCapitalize="off"
        onSubmit={handleSubmit}
        initialData={user}
        context={{ id: user.id }}
        noValidate
      >
        <DialogTitle>{userId ? 'Editar usuário' : 'Novo usuário'}</DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={12}>
              <TextField
                disabled={!!user.id}
                name="firstName"
                id="firstName"
                label="Nome"
                margin="normal"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                disabled={!!user.id}
                name="lastName"
                id="lastName"
                label="Sobrenome"
                margin="normal"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="email"
                id="email"
                label="Email"
                margin="normal"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    size="medium"
                    checked={formUserApi}
                    onClick={handleCheck}
                    disabled={
                      user?.attributes?.allowApiUsage &&
                      JSON.parse(user?.attributes?.allowApiUsage.toLowerCase())
                    }
                  />
                }
                label="Usuário API"
              />
            </Grid>
            <Grid item xs={12} style={{ display: 'flex' }}>
              {!userApi && (
                <TextField
                  name="password"
                  id="password"
                  label="Senha"
                  margin="normal"
                  type="password"
                  onChange={e => handleChangePassword(e)}
                />
              )}
            </Grid>
            {!user.id || passwordValue ? (
              <Grid item xs={12}>
                <Container
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '8px',
                  }}
                >
                  <LabelRules enable={ifMinCharacters}>
                    {ifMinCharacters && <FcCheckmark />} Pelo menos 12
                    caracteres
                  </LabelRules>
                  <LabelRules enable={ifNumber}>
                    {ifNumber && <FcCheckmark />} Pelo menos 1 número
                  </LabelRules>
                  <LabelRules enable={ifUpperCase}>
                    {ifUpperCase && <FcCheckmark />} Pelo menos 1 letra
                    maiúscula
                  </LabelRules>
                  <LabelRules enable={ifLowerCase}>
                    {ifLowerCase && <FcCheckmark />} Pelo menos 1 letra
                    minúscula
                  </LabelRules>
                  <LabelRules enable={ifSpecialCharacter}>
                    {ifSpecialCharacter && <FcCheckmark />} Pelo menos 1
                    caractere especial
                  </LabelRules>
                </Container>
              </Grid>
            ) : (
              <></>
            )}
            {user?.attributes?.apiKey && (
              <Grid item xs={12}>
                <TextField
                  disabled
                  name="apiKey"
                  id="apiKey"
                  label="Api Key"
                  margin="normal"
                  value={user?.attributes?.apiKey}
                />
                <TextField
                  disabled
                  name="secretKey"
                  id="secretKey"
                  label="Secret Key"
                  margin="normal"
                  value={user?.attributes?.secretKey}
                />
              </Grid>
            )}
            {userId && (
              <Grid item xs={12} style={{ margin: '10px 0' }}>
                <AccessControl can="update_user">
                  <Button
                    onClick={handleResetQrCode}
                    variant="outlined"
                    disabled={
                      loading ||
                      !user.enabled ||
                      !userHasQrCode ||
                      qrCodeHasBeenReseted
                    }
                    color="primary"
                    style={{ marginRight: 8 }}
                  >
                    {loading ? (
                      <CircularProgress size={24} />
                    ) : (
                      <>
                        <Icon>replay</Icon>&nbsp;Reiniciar QrCode
                      </>
                    )}
                  </Button>
                </AccessControl>
                Lido em: {dateUserReadQrCode}
              </Grid>
            )}
            <Grid item xs={12}>
              <PermissionsSelect itemsChecks={roles} setItems={setRoles} />
              <div
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  gap: '5px',
                  alignItems: 'center',
                  marginTop: '1rem',
                }}
              >
                {roles?.map(({ roleAttributes }) => {
                  return (
                    <Roles
                      key={roleAttributes?.roleName}
                      backgrounded={backgroundColorRole(
                        roleAttributes?.roleName
                      )}
                    >
                      {roleAttributes?.roleName}
                    </Roles>
                  );
                })}
              </div>
            </Grid>
            <Grid item xs={12}>
              <ProductsSelect itemsChecks={products} setItems={setProducts} />
              <div
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  gap: '5px',
                  alignItems: 'center',
                  marginTop: '1rem',
                }}
              >
                {products &&
                  products?.map(product => {
                    return (
                      <Chip
                        key={product.id}
                        label={product.titulo}
                        avatar={<Avatar src={product.imagem} />}
                        style={{
                          backgroundColor: 'transparent',
                          width: '100%',
                          justifyContent: 'flex-start',
                        }}
                      />
                    );
                  })}
              </div>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          style={{
            padding: ' 16px 24px',
            justifyContent: 'space-between',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              flex: 8,
              justifyContent: 'flex-end',
            }}
          >
            <Button onClick={onClose} variant="outlined" color="primary">
              Cancelar
            </Button>
            <AccessControl can={['update_user', 'create_user']}>
              <Button
                type="submit"
                variant="contained"
                disabled={loading}
                color="primary"
                style={{ marginLeft: 8 }}
              >
                {loading ? (
                  <CircularProgress size={24} />
                ) : (
                  <>
                    <Icon>save</Icon>&nbsp;Salvar
                  </>
                )}
              </Button>
            </AccessControl>
          </div>
        </DialogActions>
      </Form>
    </Dialog>
  );
}

DialogNewUser.defaultProps = {
  user: {},
};

DialogNewUser.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string,
    enabled: PropTypes.bool,
    createdTimestamp: PropTypes.number,
    username: PropTypes.string,
    email: PropTypes.string,
    clientRoles: PropTypes.oneOfType([PropTypes.array]),
    attributes: PropTypes.shape({
      productPermissions: PropTypes.oneOfType([PropTypes.array]),
      qrCodeSecretKey: PropTypes.string,
      dateQrcodeRead: PropTypes.string,
      allowApiUsage: PropTypes.string,
      secretKey: PropTypes.string,
      apiKey: PropTypes.string,
    }),
  }),
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  setRefetch: PropTypes.func.isRequired,
  refetchState: PropTypes.bool.isRequired,
};
