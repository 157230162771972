import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding: 0px 12px;
  display: flex;
  gap: 16px;
  align-items: center;
`;

export const DialogContainer = styled.div`
  display: flex;
  gap: 2rem;
  margin-bottom: 32px;
`;

export const CustomChip = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 8px;
  background: ${({ background }) => background ?? 'transparent'};
  border-radius: 34px;
`;
