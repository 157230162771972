import styled from 'styled-components';

export const Tag = styled.div`
  height: 20px;
  background-color: #ff5865;
  text-align: center;
  color: white;
  margin: 0.2rem;
  border-radius: 1rem;
  width: 100px;
`;
