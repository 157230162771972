import React from 'react';
import { Tag } from './styles';

const EvironmentTag = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;

  if (baseUrl) {
    if (baseUrl.includes('sandbox')) {
      return <Tag className="red-line">Homologação</Tag>;
    }

    if (baseUrl.includes('localhost')) {
      return <Tag className="red-line">Local</Tag>;
    }

    if (baseUrl.includes('.run.app')) {
      return <Tag className="red-line">Dinâmico</Tag>;
    }
  }

  return null;
};

export default EvironmentTag;
