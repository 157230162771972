import React, { useState } from 'react';
import { Popover, Typography } from '@material-ui/core';
import { RiInformationLine } from 'react-icons/ri';
import PropTypes from 'prop-types';
import * as S from './styles';

export default function DetailProducts({ disabledAccount }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const openApplication = Boolean(anchorEl);

  return (
    <>
      <S.InfoButton onClick={e => setAnchorEl(e.currentTarget)}>
        <RiInformationLine size={16} />
      </S.InfoButton>

      <Popover
        open={openApplication}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          style: {
            borderRadius: '12px',
            padding: '16px',
          },
        }}
      >
        <S.RowContainer>
          <S.ColumnContainer>
            <S.ApplicationLabel>Cadastro em</S.ApplicationLabel>
            <Typography>
              {disabledAccount.user.pessoa.application.nome}
            </Typography>
          </S.ColumnContainer>

          <S.ColumnContainer>
            <S.ApplicationLabel>Última compra em</S.ApplicationLabel>
            <Typography>{disabledAccount.compra.nome ?? '-'}</Typography>
          </S.ColumnContainer>

          <S.ColumnContainer>
            <S.ApplicationLabel>Encerrou conta em</S.ApplicationLabel>
            <Typography>{disabledAccount.application.nome}</Typography>
          </S.ColumnContainer>
        </S.RowContainer>
      </Popover>
    </>
  );
}

DetailProducts.propTypes = {
  disabledAccount: PropTypes.oneOfType([PropTypes.object]).isRequired,
};
